import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { DeviceStored } from 'kiisu-api-types/devices';
import { useNavigate, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon } from '@primer/octicons-react';
import CustomSelect from '../components/CustomSelect';
import { checkAPIError } from '../services/ErrorService';
import { ObjectMeta } from 'kiisu-api-types/common';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';
import { Banner } from '@primer/react/experimental';
import { getLocations } from '../api/api.locations';
import { deleteDevice, getDevice, patchDevice, postDevice } from '../api/api.devices';

interface FormValues {
  locationId: string;
  name: string;
  intIp: string;
  extIp: string;
  metadata: ObjectMeta;
}

function DevicesForm(props: { preloadedValues?: FormValues; error: string | undefined; handleError: any }) {
  const [locations, setLocations] = useState<Record<string, string>>();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  useEffect(() => {
    getLocations(true)
      .then((response) => {
        props.handleError(undefined);
        setLocations(
          Object.assign({}, ...response.data.items.map((location) => ({ [location.metadata.id]: location.name })))
        );
      })
      .catch((error) => props.handleError(error));
    // eslint-disable-next-line
  }, []);

  async function onSubmit(form: FormValues) {
    if (props.preloadedValues && props.preloadedValues.metadata.id) {
      patchDevice(form)
        .then(() => navigate('/devices'))
        .catch((error) => props.handleError(error));
    } else {
      postDevice(form)
        .then(() => navigate('/devices'))
        .catch((error) => props.handleError(error));
    }
  }

  async function removeDevice() {
    deleteDevice(props.preloadedValues!.metadata.id, props.preloadedValues!.metadata.resourceVersion)
      .then(() => navigate('/devices'))
      .catch((error) => props.handleError(error));
  }

  const locationOptions = Object.keys(locations ? locations : []).map((data) => ({
    label: locations![data],
    value: data
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => props.handleError(undefined)}>
      <Box sx={{ display: 'grid', gap: 3, pt: 4 }}>
        <FormControl>
          <FormControl.Label>Nimi</FormControl.Label>
          <TextInput block {...register('name', { required: { value: true, message: 'Määra seadme nimetus' } })} />
          {errors.name && <FormControl.Validation variant="error">{errors.name.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="location-select">Asukoht</FormControl.Label>
          <Controller
            name="locationId"
            control={control}
            rules={{ required: { value: true, message: 'Vali seadme asukoht' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="location-select"
                placeholder="Vali asukoht..."
                value={locationOptions.filter((c) => value === c.value)}
                onChange={(val) => onChange(val!.value)}
                options={locationOptions}
                aria-errormessage={errors.locationId?.message}
                aria-invalid={!!errors.locationId}
              />
            )}
          />
          {errors.locationId && (
            <FormControl.Validation variant="error">{errors.locationId.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>URL</FormControl.Label>
          <TextInput block {...register('intIp', { required: { value: true, message: 'Määra seadme URL' }})} />
          {errors.intIp && <FormControl.Validation variant="error">{errors.intIp.message}</FormControl.Validation>}
        </FormControl>

        <FormFooter>
          <Button onClick={() => navigate('/devices')} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>

          {props.preloadedValues && props.preloadedValues.metadata.id && (
            <Button onClick={() => removeDevice()} variant="danger">
              Kustuta
            </Button>
          )}
        </FormFooter>
      </Box>
    </form>
  );
}

function DevicesChangeForm() {
  useDocumentTitle(['Seadmed', 'Seade']);

  const { id } = useParams();
  const [apiError, setApiError] = useState<string>();
  const [entity, setEntity] = useState<DeviceStored>();

  function handleError(error: any) {
    setApiError(checkAPIError(error));
    if (error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (id) {
      getDevice(id)
        .then((result) => setEntity(result.data))
        .catch((error) => setApiError(checkAPIError(error)));
    }
  }, [id]);

  function dataToFormData(entity?: DeviceStored) {
    if (entity) {
      return {
        locationId: entity.locationId,
        name: entity.name,
        intIp: entity.intIp,
        extIp: entity.extIp,
        metadata: entity.metadata
      };
    }
  }

  return (
    <ContentContainer>
      {apiError && (
        <Banner style={{ padding: '0.75rem 0.5rem', marginBottom: '1rem' }} variant="critical" title={apiError} />
      )}
      <PageHead title={id ? 'Muuda seadme andmeid' : 'Lisa uus seade'}></PageHead>
      {!id || entity ? (
        <DevicesForm preloadedValues={dataToFormData(entity)} error={apiError} handleError={handleError} />
      ) : (
        <></>
      )}
    </ContentContainer>
  );
}

export default DevicesChangeForm;
