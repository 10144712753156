import { DataTable } from '@primer/react/experimental';
import React, { useEffect, useState } from 'react';
import IntroductionBlankslate from './IntroductionBlankslate';
import { Box } from '@primer/react';

interface ListProps {
  rows: any[];
  columns: any[];
  blankslateText: string;
}

export function getColumns(columns: any[]) {
  return window.innerWidth > 1012 ? columns : columns.filter((c) => !c.hideOnSmall);
}

function ListContent(props: ListProps) {
  const [visibleColumns, setVisibleColumns] = useState<any[]>(getColumns(props.columns));

  useEffect(() => {
    function resizeEvent() {
      setVisibleColumns(getColumns(props.columns));
    }

    window.addEventListener('resize', () => resizeEvent());
    return () => {
      window.removeEventListener('resize', () => resizeEvent());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="data-table-container" sx={{ display: 'flex', flexDirection: 'column', mt: '1rem' }}>
      {props.rows.length === 0 && <IntroductionBlankslate heading={props.blankslateText} />}
      {props.rows.length > 0 && <DataTable data={props.rows} columns={visibleColumns} cellPadding="spacious" />}
    </Box>
  );
}

export default ListContent;
