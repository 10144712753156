import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// To disable text input zoom on iOS Safari as it stays on after login, making page default view be zoomed in.
const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    const re = /maximum-scale=[0-9.]+/g;

    if (content && re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
};

const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

const checkIsIOSSafari = () => {
  const ua = navigator.userAgent;
  return ua.match(/iPad|iPhone|iPod/i) && ua.match(/WebKit/i) && !ua.match(/CriOS/i);
};

if (checkIsIOSSafari()) {
  disableIosTextFieldZoom();
}
