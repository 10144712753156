import { createRestypedAxios } from './base.api';
import { KiisuAPI } from 'kiisu-api-types';
import { DeviceNew, DeviceStored } from 'kiisu-api-types/devices';

const kiisuAPI = createRestypedAxios<KiisuAPI>('/rest/');

export function getAlarms() {
  return kiisuAPI.get<'/devices/v1/alarms'>('/devices/v1/alarms', {
    params: {
      interval: 30
    }
  });
}

export function getDevices() {
  return kiisuAPI.get<'/devices/v1/devices'>('/devices/v1/devices');
}

export function getDevice(id: string) {
  return kiisuAPI.get<'/devices/v1/devices/:id'>(`/devices/v1/devices/${id}`);
}

export function postDevice(data: DeviceNew) {
  return kiisuAPI.post('/devices/v1/devices', data);
}

export function patchDevice(device: DeviceStored) {
  return kiisuAPI.patch<'/devices/v1/devices/:id'>(`/devices/v1/devices/${device.metadata.id}`, device);
}

export function deleteDevice(id: string, resourceVersion: number) {
  return kiisuAPI.delete<'/devices/v1/devices/:id/:resourceVersion'>(`/devices/v1/devices/${id}/${resourceVersion}`);
}
