import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@primer/react';

export const KiisuGlobalStyles = createGlobalStyle`
  * {
    border-color: #3d444d !important;
  }

  html {
    background-color: ${themeGet('colors.canvas.default')};
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji' ! default;
    font-size: 0.875em;
    line-height: 1em;
    color: ${themeGet('colors.checks.textLink')};
    background-color: ${themeGet('colors.canvas.dark')};
    min-height: 100vh;
    min-height: 100dvh; // For not losing header on iOS Safari.
  }

  a {
    color: ${themeGet('colors.accent.fg')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  section[data-variant="critical"] {
    border-color: ${themeGet('colors.danger.muted')} !important;
  }

  // Do not show toggle switch On/Off labels
  #notifications-switch > span {
    display: none;
  }

  input[type="text" i] {
    padding: 4px 12px;
  }

  .thumbnail {
    width: auto;
    display: block;
    cursor: pointer;
    image-orientation: from-image;
  }

  .caption {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // DatePicker global styles for dark mode support using primer theme colors
  .react-datepicker {
    color: ${themeGet('colors.fg.default')};
    background-color: ${themeGet('colors.canvas.overlay')};
    border-color: ${themeGet('colors.border.default')};
  }

  .form-control, .form-select {
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
    color: ${themeGet('colors.fg.default')};
    vertical-align: middle;
    background-color: ${themeGet('colors.canvas.default')};
    background-repeat: no-repeat;
    background-position: right 8px center;
    border: 1px solid ${themeGet('colors.border.default')};
    border-radius: 6px;
    box-shadow: ${themeGet('shadows.primer.shadow.inset')};
    transition: 80ms cubic-bezier(0.33, 1, 0.68, 1);
    transition-property: color, background-color, box-shadow, border-color;
  }

  .input-block {
    display: block;
    width: 100%;
  }

  .react-datepicker__tab-loop {
    margin: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
    align-self: stretch;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container {
    font-size: 14px;
    line-height: 20px;
    color: ${themeGet('colors.fg.default')};
    vertical-align: middle;
    background-color: ${themeGet('colors.canvas.default')};
    border: 1px solid ${themeGet('colors.border.default')};
    border-radius: 6px;
    outline: none;
    box-shadow: ${themeGet('shadows.primer.shadow.inset')};
    -webkit-box-align: stretch;
    align-items: stretch;
    min-height: 32px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-self: stretch;
  }

  .react-datepicker__header {
    background-color: ${themeGet('colors.canvas.subtle')};
    border-bottom-color: ${themeGet('colors.border.default')};
  }

  .react-datepicker__time-container {
    border-left-color: ${themeGet('colors.border.default')};
  }

  .react-datepicker__time-container .react-datepicker__time {
    background: ${themeGet('colors.canvas.overlay')};
  }

  .react-datepicker * {
    color: ${themeGet('colors.fg.default')};
  }

  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: ${themeGet('colors.accent.emphasis')} !important;
    color: ${themeGet('colors.fg.onEmphasis')} !important;
  }

  .react-datepicker__day:hover, li.react-datepicker__time-list-item:hover {
    background-color: ${themeGet('colors.neutral.subtle')} !important;
    color: ${themeGet('colors.fg.default')};
  !important;
  }

  .react-datepicker__triangle {
    stroke: ${themeGet('colors.canvas.overlay')} !important;
    fill: ${themeGet('colors.canvas.overlay')} !important;
    color: ${themeGet('colors.border.default')} !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${themeGet('colors.accent.emphasis')} !important;
  }

  .single-date-time-picker-calendar {
    width: 320px;
  }

  .single-date-time-picker-calendar > .react-datepicker__month-container {
    width: 236px;
  }

  .single-date-time-picker-calendar > .react-datepicker__time-container {
    width: 82px;
  }

  .react-datepicker__children-container {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  button.react-datepicker__close-icon::after {
    background-color: inherit;
    color: ${themeGet('colors.fg.default')};
    font-size: 14px;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container:has(> .input-block.invalid-date) {
    border-color: ${themeGet('colors.danger.emphasis')};;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container:has(> .input-block.invalid-date:focus-visible) {
    border-color: ${themeGet('colors.accent.fg')};
  }

  .data-table-container table {
    font-size: unset !important;
  }

  .date-range-picker-calendar {
    width: 248px;
  }

  .date-range-picker-calendar > .react-datepicker__month-container {
    width: 246px;
  }

  .react-datepicker__children-container {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  button.react-datepicker__close-icon::after {
    background-color: inherit;
    color: ${themeGet('colors.fg.default')};
    font-size: 14px;
  }

  .upload-container input {
    overflow: hidden;
    position: absolute;
    z-index: -1;
    opacity: 0;
    max-width: 97px;
  }

  [data-color-mode=dark][data-dark-theme*=dark],
  [data-color-mode=light][data-light-theme*=dark] {
    color-scheme: dark;
  }

  form input:disabled {
    background-color: ${themeGet('colors.btn.bg')};
  }

  @media screen and (min-width: 544px) {
    .date-range-picker-calendar {
      width: 100%;
    }

    .date-range-picker-calendar > .react-datepicker__month-container {
      width: initial;
    }

    .single-date-time-picker-calendar {
      width: 100%;
    }

    .single-date-time-picker-calendar > .react-datepicker__month-container {
      width: initial;
    }

    .single-date-time-picker-calendar > .react-datepicker__time-container {
      width: 86px;
    }
  }
`;
