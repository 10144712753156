import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { et } from 'date-fns/locale/et';
import DatePickerActions, { DatePickerAction } from './DatePickerActions';
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns';

export declare type DateRange = [Date | undefined, Date | undefined];

function DateRangePicker(props: { name: string; value: DateRange; onChange: any }) {
  const datepickerRef = useRef<DatePicker>(null);

  const onChange = (range: DateRange) => {
    props.onChange(range);
    datepickerRef.current?.setOpen(false);
  };

  const actions: DatePickerAction[] = [
    {
      label: 'Täna',
      date: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: 'Eile',
      date: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))]
    },
    {
      label: 'See nädal',
      date: [startOfISOWeek(new Date()), endOfISOWeek(new Date())]
    },
    {
      label: 'Eelmine nädal',
      date: [startOfISOWeek(subWeeks(new Date(), 1)), endOfISOWeek(subWeeks(new Date(), 1))]
    },
    {
      label: 'See kuu',
      date: [startOfMonth(new Date()), endOfMonth(new Date())]
    },
    {
      label: 'Eelmine kuu',
      date: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
    },
    {
      label: 'See aasta',
      date: [startOfYear(new Date()), endOfYear(new Date())]
    },
    {
      label: 'Eelmine aasta',
      date: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))]
    }
  ];

  return (
    <DatePicker
      ref={datepickerRef}
      placeholderText="Vali kuupäevade vahemik"
      name={props.name}
      className="form-control input-block"
      calendarClassName="date-range-picker-calendar"
      wrapperClassName="input-block"
      selected={props.value && props.value[0]}
      startDate={props.value && props.value[0]}
      endDate={props.value && props.value[1]}
      selectsRange
      shouldCloseOnSelect={true}
      monthsShown={2}
      showPreviousMonths={false}
      isClearable={true}
      locale={et}
      dateFormat="dd.MM.yyyy"
      onChange={props.onChange}>
      <DatePickerActions onChange={onChange} actions={actions} />
    </DatePicker>
  );
}

export default DateRangePicker;
