import { createAxios } from './base.api';

const axiosInstance = createAxios('/rest/');

export interface Data {
  i: string;
  t: string;
  dt: string;
  j: any;
  v: number;
}

export function getEntity(path: string) {
  return axiosInstance.get<Data>(path);
}

export function getEntityById(path: string, id: string) {
  return axiosInstance.get<Data>(path + '/' + id);
}

export function getEntities(path: string, params?: any) {
  return axiosInstance.get<Data[]>(path, { params });
}

export function addEntity(path: string, data: any) {
  return axiosInstance.post(path, data);
}

export function changeEntity(path: string, entity: any, data: any) {
  return axiosInstance.patch(path + '/' + entity.i, {
    i: entity.i,
    t: entity.t,
    j: data,
    v: entity.v
  });
}

export function changeMyData(entity: any, data: any) {
  return axiosInstance.patch('users/current/user', {
    i: entity.i,
    t: entity.t,
    j: data,
    v: entity.v
  });
}

export function getEntitiesByReference(path: string, label: string, value: string) {
  return axiosInstance.get<Data[]>(path + '?' + label + '=' + value);
}

export function deleteEntity(path: string, id: string, version: number) {
  return axiosInstance.delete(path + '/' + id + '?v=' + version);
}

export function login(username: string, password: string) {
  return axiosInstance.post('auth/login', {
    username: username,
    password: password
  });
}

export function logout() {
  return axiosInstance.post('auth/logout', {});
}

export function uploadFile(path: string, file: File) {
  const formData = new FormData();
  formData.append('attachment', file);
  return axiosInstance.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function retrieveFile(path: string, hash: string) {
  return axiosInstance.get(path + '/' + hash, {
    responseType: 'blob'
  });
}

export function sendEmail(path: string, data: any) {
  return axiosInstance.post(path, data);
}
