import React from 'react';
import { BaseStyles, ThemeProvider } from '@primer/react';
import Router from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import { KiisuGlobalStyles } from './KiisuGlobalStyles';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider colorMode="auto">
        <KiisuGlobalStyles />
        <BaseStyles>
          <Router />
        </BaseStyles>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
