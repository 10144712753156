import { Box, Octicon, useTheme } from '@primer/react';
import { XIcon } from '@primer/octicons-react';
import ReactSignatureCanvas from 'react-signature-canvas';
import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@primer/react';

const StyledSignatureContainer = styled(Box)`
  display: flex;
  height: 220px;
  width: 100%;

  &:has(>img) {
    height: auto !important;
  }

  & img {
    height: auto;
    width: 100%;
  }

  & canvas.signature-canvas {
    height: 100%;
    width: 100%;
    border: 1px solid ${themeGet('colors.border.default')};
    border-radius: 6px;
    box-shadow: ${themeGet('shadows.primer.inset')};
  }

  @media screen and (min-width: 544px) {
    height: 300px;

    & > img {
      height: 220px;
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    height: 340px;
  }

  @media (min-width: 1400px) {
    height: 360px;
  }
`;

function SignatureContainer(props: {
  clearSignature?: any;
  signatureRef?: any;
  viewOnly?: boolean;
  signatureImage?: any;
}) {
  const { theme } = useTheme();

  return (
    <StyledSignatureContainer>
      {props.viewOnly ? (
        <img src={props.signatureImage} alt="Kliendi allkiri" />
      ) : (
        <Box>
          <Box position="relative">
            <Box position="absolute" onClick={props.clearSignature} right={0} p={2}>
              <Octicon icon={XIcon} size={24} color={theme!.colors.fg.muted} />
            </Box>
          </Box>

          <ReactSignatureCanvas
            penColor="grey"
            clearOnResize={false}
            minWidth={0.9}
            maxWidth={0.9}
            canvasProps={{
              id: 'clientSignature',
              className: 'signature-canvas'
            }}
            ref={props.signatureRef}
          />
        </Box>
      )}
    </StyledSignatureContainer>
  );
}

export default SignatureContainer;
